import { type ReactNode, memo, createContext, useContext, Children } from "react";

const ListContext = createContext<{ depth: number } | null>(null);

interface ListProps {
  children: ReactNode;
  title?: ReactNode;
}
export const List = memo(function List({ title, children }: ListProps) {
  const listContext = useContext(ListContext);

  const listItems = Children.toArray(children).flatMap((child, index, children) =>
    index !== children.length - 1 ? [child, "\n"] : child,
  );

  return (
    <ListContext.Provider value={{ depth: listContext?.depth !== undefined ? listContext.depth + 1 : 0 }}>
      {listContext ? "\n" : ""}
      {/* <pre className="whitespace-break-spaces"> */}
        {title ? <span className="block">{title}{"\n"}</span> : ''}
        <ul>{listItems}</ul>
      {/* </pre> */}
    </ListContext.Provider>
  );
});

interface ListItemProps {
  children: ReactNode;
};
export const ListItem = memo(function ListItem({children}: ListItemProps) {
  const listContext = useContext(ListContext);
  if (!listContext) throw new Error("<ListItem /> must be used inside <List />");
  // return <>{" ".repeat(2 * listContext.depth)}- {children}</>
  return <li>{children}</li>
});
